.custom-component-class>.k-animation-container {
    width: 100%
}

.k-listbox .k-list-scroller .k-list.k-list-md {
    height: auto;
    max-height: 100%;
}

.overflow-y-auto>.k-grid-container>.k-grid-content {
    overflow-y: auto !important;
}

.overflow-y-auto>.k-grid-header {
    padding-inline-end: 0 !important;
}